// Depenses.js

import React, { useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Header from "components/Header";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import Popup from "components/Popup";
import { Link } from "react-router-dom";
import { DeleteOutline } from "@material-ui/icons";
import "./userList.css";
import { useGetPrestationsQuery } from "state/api";
import axios from "axios";
import { server } from "server";
import FormDepense from "scenes/formDepense";
import StatBox from "components/StatBox";
import { MonetizationOn } from "@mui/icons-material";

const Depenses = () => {
  const theme = useTheme();
  const { data } = useGetPrestationsQuery();
  const [prestations, setPrestations] = useState([]);
  const IdPrestation = localStorage.getItem("depenseId");
  const [dailyDepenses, setDailyDepenses] = useState(0);

  useEffect(() => {
    // Fetch data from backend when component mounts
    const fetchData = async () => {
      try {
        const response = await axios.get(`${server}/depense/get-depenses`);
        let prestationsArray = response.data.prestations || [];
        prestationsArray = prestationsArray.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setPrestations(prestationsArray);

        const dailyResponseDepense = await axios.get(
          `${server}/commande/api/all-commande-depense/daily`
        );
        setDailyDepenses(dailyResponseDepense.data.total);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const [openPopup, setOpenPopup] = useState(false);

  const handleDelete = async (prestationsId) => {
    try {
      const IdPrestation = await localStorage.getItem("depenseId");
      if (window.confirm("Veux-tu supprimer cette depense?")) {
        const response = await axios.delete(
          `${server}/depense/delete-depense/${IdPrestation}/${prestationsId}`
        );
        if (response.data.success) {
          window.location.reload(true);
        } else {
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      field: "createdAt",
      headerName: "Effectué le",
      flex: 1,
    },
    {
      field: "name",
      headerName: "Nom du depense",
      flex: 2,
    },
    {
      field: "desc",
      headerName: "Description",
      flex: 2,
    },
    {
      field: "price",
      headerName: "Somme en fbu",
      flex: 1,
    },
    {
      field: "userName",
      headerName: "Fait par",
      flex: 1,
    },

    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/depense/${IdPrestation}/` + params.row.id}>
              <button className="userListEdit">Edit</button>
            </Link>
            <DeleteOutline
              className="userListDelete"
              onClick={() => handleDelete(params.row.id)}
            />
          </>
        );
      },
    },
  ];

  const formatNumber = (number) => {
    return number.toLocaleString();
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header
        title="Les dépenses et achats"
        subtitle="Prix de differentes dépenses"
      />
      <Box flex="1 1 300px">
        <StatBox
          title=" Les dépenses"
          value={formatNumber(dailyDepenses)}
          increase="fbu"
          icon={
            <MonetizationOn
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />
      </Box>
      <br />
      <Box
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={prestations.map((prestation) => ({
            id: prestation._id,
            name: prestation.name,
            userName: prestation.userName,
            desc: prestation.desc,
            price: prestation.price,
            createdAt: prestation.createdAt.slice(0, 10),
          }))}
          columns={columns}
          pageSize={8}
          components={{ Toolbar: DataGridCustomToolbar }}
          componentsProps={{
            toolbar: { openPopup, setOpenPopup },
          }}
        />
      </Box>
      <Popup
        title="Ajouter une nouvelle dépense"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <FormDepense setOpenPopup={setOpenPopup} />
      </Popup>
    </Box>
  );
};

export default Depenses;
