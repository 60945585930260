// StockRecord.js

import React, { useEffect, useState } from "react";
import { Box, TextField, useTheme, Button } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "components/Header";
import axios from "axios";
import { toast } from "react-toastify";
import { server } from "../../server"; // Adjust the path if necessary

const StockRecord = () => {
  const theme = useTheme();

  // State variables
  const [records, setRecords] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // Fetch all stock records on component mount
  useEffect(() => {
    fetchAllStockRecords();
  }, []);

  const fetchAllStockRecords = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${server}/product/get-all-stockRecord`);
      setRecords(response.data.allRecords);
      setLoading(false);
    } catch (error) {
      toast.error("Ces données ne sont pas disponibles");
      setError("Ces données ne sont pas disponibles");
      setLoading(false);
    }
  };

  const handleDateChange = (event) => {
    const date = event.target.value;
    setSelectedDate(date);

    if (date === "") {
      // If date is cleared, fetch all records
      fetchAllStockRecords();
    } else {
      fetchStockRecordsByDate(date);
    }
  };

  const fetchStockRecordsByDate = async (date) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${server}/product/stockRecord-by-date/${date}`
      );
      setRecords(response.data.records);
      setLoading(false);
    } catch (error) {
      toast.error("Ces données ne sont pas disponibles");
      setError("Ces données ne sont pas disponibles");
      setLoading(false);
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "name",
      headerName: "Nom",
      flex: 1,
    },
    {
      field: "desc",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "stock",
      headerName: "Quantité Ajoutée",
      flex: 1,
    },
    {
      field: "userName",
      headerName: "Fait par",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Ajouté le",
      flex: 1,
    },
  ];

  const rows = records.map((item) => ({
    id: item._id,
    name: item.name,
    desc: item.desc,
    stock: item.stock,
    userName: item.userName,
    createdAt: item.createdAt.slice(0, 10),
  }));

  return (
    <Box m="1.5rem 2.5rem">
      <Header
        title="Ajouts des Produits"
        subtitle="Liste des Produits acheter"
      />
      <br />
      <TextField
        id="date"
        label="Sélectionner la Date"
        type="date"
        value={selectedDate}
        onChange={handleDateChange}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          setSelectedDate("");
          fetchAllStockRecords();
        }}
        style={{ marginLeft: "10px" }}
      >
        Réinitialiser
      </Button>
      <Box
        height="80vh"
        sx={{
          marginTop: "20px",
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          loading={loading}
        />
      </Box>
    </Box>
  );
};

export default StockRecord;
