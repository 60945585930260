// Sidebar.js

import React, { useEffect, useState } from "react";
import {
  Box,
  Collapse,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import {
  ChevronLeft,
  ChevronRightOutlined,
  HomeOutlined,
  ShoppingCartOutlined,
  Groups2Outlined,
  ReceiptLongOutlined,
  TodayOutlined,
  CalendarMonthOutlined,
  AdminPanelSettingsOutlined,
  PointOfSaleOutlined,
  HelpOutlineOutlined,
  ExpandLess,
  ExpandMore,
  SettingsOutlined,
} from "@mui/icons-material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import FlexBetween from "./FlexBetween";
import logoImage from "assets/ILLUSTRATION LOGO BISMOS .png";

const navItems = [
  {
    text: "Acceuil",
    icon: <HomeOutlined />,
    path: "/acceuil",
  },
  {
    text: "Modules",
    icon: null,
  },
  {
    text: "Achats",
    icon: <ShoppingCartOutlined />,
    path: "/achats",
  },
  {
    text: "Commandes",
    icon: <ShoppingCartOutlined />,
    path: "/commandes",
  },
  {
    text: "Stocks",
    icon: <ReceiptLongOutlined />,
    path: "/stocks",
  },
  {
    text: "Depenses",
    icon: <ReceiptLongOutlined />,
    path: "/depenses",
  },
  {
    text: "Employés",
    icon: <Groups2Outlined />,
    path: "/employés",
  },
  {
    text: "Devis",
    icon: <ReceiptLongOutlined />,
    path: "/devis",
  },
  {
    text: "Les Comptes",
    icon: null,
    subItems: [
      {
        text: "Comptabilité",
        icon: <TodayOutlined />,
        path: "/Comptabilité",
      },
      {
        text: "Facturation",
        icon: <ReceiptLongOutlined />,
        path: "/facturation",
      },
      {
        text: "Dépots-banque",
        icon: <ReceiptLongOutlined />,
        path: "/Dépots-banque",
      },
      {
        text: "Mensuel",
        icon: <CalendarMonthOutlined />,
        path: "/mensuel",
      },
    ],
  },
  {
    text: "Management",
    icon: null,
    subItems: [
      {
        text: "Admin",
        icon: <AdminPanelSettingsOutlined />,
        path: "/admin",
      },
      {
        text: "Bureautique",
        icon: <PointOfSaleOutlined />,
        path: "/bureautique",
      },
      {
        text: "FAQ",
        icon: <HelpOutlineOutlined />,
        path: "/faq",
      },
    ],
  },
];

const Sidebar = ({
  user,
  drawerWidth,
  isSidebarOpen,
  setIsSidebarOpen,
  isNonMobile,
}) => {
  const { pathname } = useLocation();
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();
  const [openSubmenus, setOpenSubmenus] = useState({});

  useEffect(() => {
    setActive(pathname.substring(1));
  }, [pathname]);

  const handleClick = (text) => {
    setOpenSubmenus((prevState) => ({
      ...prevState,
      [text]: !prevState[text],
    }));
  };

  const renderNavItem = (item) => {
    if (item.subItems) {
      return (
        <React.Fragment key={item.text}>
          <ListItemButton
            onClick={() => handleClick(item.text)}
            sx={{
              paddingLeft: "2rem",
              paddingRight: "2rem",
            }}
          >
            <ListItemText
              primary={item.text}
              sx={{
                color: theme.palette.secondary[100],
              }}
            />
            {openSubmenus[item.text] ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openSubmenus[item.text]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {item.subItems.map((subItem) => (
                <ListItemButton
                  key={subItem.text}
                  onClick={() => {
                    navigate(subItem.path);
                    setActive(subItem.path.substring(1));
                  }}
                  sx={{
                    backgroundColor:
                      active === subItem.path.substring(1)
                        ? theme.palette.secondary[300]
                        : "transparent",
                    color:
                      active === subItem.path.substring(1)
                        ? theme.palette.primary[600]
                        : theme.palette.secondary[100],
                    paddingLeft: "3rem",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color:
                        active === subItem.path.substring(1)
                          ? theme.palette.primary[600]
                          : theme.palette.secondary[200],
                      minWidth: "36px",
                    }}
                  >
                    {subItem.icon}
                  </ListItemIcon>
                  <ListItemText primary={subItem.text} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
        </React.Fragment>
      );
    }

    if (!item.icon) {
      return (
        <Typography
          key={item.text}
          sx={{ m: "2.25rem 0 1rem 3rem", color: theme.palette.secondary[200] }}
        >
          {item.text}
        </Typography>
      );
    }

    const lcText = item.text.toLowerCase();

    return (
      <ListItem key={item.text} disablePadding>
        <ListItemButton
          onClick={() => {
            navigate(item.path);
            setActive(lcText);
          }}
          sx={{
            backgroundColor:
              active === lcText ? theme.palette.secondary[300] : "transparent",
            color:
              active === lcText
                ? theme.palette.primary[600]
                : theme.palette.secondary[100],
            paddingLeft: "2rem",
            paddingRight: "2rem",
          }}
        >
          <ListItemIcon
            sx={{
              color:
                active === lcText
                  ? theme.palette.primary[600]
                  : theme.palette.secondary[200],
              minWidth: "36px",
            }}
          >
            {item.icon}
          </ListItemIcon>
          <ListItemText primary={item.text} />
          {active === lcText && <ChevronRightOutlined sx={{ ml: "auto" }} />}
        </ListItemButton>
      </ListItem>
    );
  };

  return (
    <Box component="nav">
      {isSidebarOpen && (
        <Drawer
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          variant="persistent"
          anchor="left"
          sx={{
            width: drawerWidth,
            "& .MuiDrawer-paper": {
              color: theme.palette.secondary[200],
              backgroundColor: theme.palette.background.alt,
              boxSizing: "border-box",
              borderWidth: isNonMobile ? 0 : "2px",
              width: drawerWidth,
            },
          }}
        >
          <Box width="100%">
            <Box m="1.5rem 2rem 2rem 3rem">
              <Box
                component="img"
                alt="logo bismos"
                src={logoImage}
                height="130px"
                width="130px"
                sx={{ objectFit: "cover", marginLeft: "7px" }}
              />
              <FlexBetween color={theme.palette.secondary.main}>
                <Box display="flex" alignItems="center" gap="0.5rem">
                  <Typography variant="h5" fontWeight="bold" color="white">
                    © BISMOS PRINT
                  </Typography>
                </Box>
                {!isNonMobile && (
                  <IconButton
                    onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                    sx={{ color: theme.palette.secondary[100] }}
                  >
                    <ChevronLeft />
                  </IconButton>
                )}
              </FlexBetween>
            </Box>
            <List>{navItems.map((item) => renderNavItem(item))}</List>
          </Box>

          <Box position="relative" bottom="2rem">
            <Divider />
            <FlexBetween textTransform="none" gap="1rem" m="1.5rem 2rem 0 3rem">
              <Box
                component="img"
                alt="profile"
                src={`${user?.profilepic?.url}`}
                height="40px"
                width="40px"
                borderRadius="50%"
                sx={{ objectFit: "cover" }}
              />
              <Box textAlign="left">
                <Typography
                  fontWeight="bold"
                  fontSize="0.9rem"
                  sx={{ color: theme.palette.secondary[100] }}
                >
                  {user?.name}
                </Typography>
                <Typography
                  fontSize="0.8rem"
                  sx={{ color: theme.palette.secondary[200] }}
                >
                  {user?.occupation}
                </Typography>
              </Box>
              <Link to="/settings">
                <SettingsOutlined
                  sx={{
                    color: theme.palette.secondary[300],
                    fontSize: "25px",
                  }}
                />
              </Link>
            </FlexBetween>
          </Box>
        </Drawer>
      )}
    </Box>
  );
};

export default Sidebar;
