import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  useTheme,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "components/Header";
import Popup from "components/Popup";
import { Link } from "react-router-dom";
import { DeleteOutline } from "@material-ui/icons";
import { useGetUserQuery } from "state/api";
import axios from "axios";
import { server } from "../../../src/server";
import "./commande.css";
import FormMainCommand from "scenes/formMainCommand";
import { toast } from "react-toastify";
import FlexBetween from "components/FlexBetween";
import { useSelector } from "react-redux";

const DailyCommandeWithPayments = () => {
  const theme = useTheme();
  const userId = useSelector((state) => state.global.userId);
  const { data } = useGetUserQuery(userId);
  const [loading, setLoading] = useState(false);
  const [prestations, setPrestations] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${server}/commande/commandes-with-payments-with-totals-by-payments`
      );
      const prestationsArray = response.data.commandes || [];
      setPrestations(prestationsArray);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const formatNumber = (number) => {
    return Number(number || 0).toLocaleString();
  };

  const handleDelete = async (id) => {
    try {
      if (window.confirm("Veux-tu supprimer cette commande?")) {
        const response = await axios.delete(
          `${server}/commande/delete-commande/${id}`
        );
        if (response) {
          toast.success("Commande supprimée avec succès");
          fetchData();
        } else {
          toast.error("Erreur lors de la suppression de la commande");
        }
      }
    } catch (error) {
      console.log(error);
      toast.error("Utilisateur introuvable");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      field: "createdAt",
      headerName: "Fait le",
      flex: 1.5,
    },
    {
      field: "nomClient",
      headerName: "Nom Client",
      flex: 2,
    },
    {
      field: "contact",
      headerName: "Contact",
      flex: 1,
    },
    {
      field: "totalQty",
      headerName: "Quantité",
      flex: 1,
      valueGetter: (params) => formatNumber(params.row.totalQty),
    },
    {
      field: "totalPriceUnitaire",
      headerName: "Prix Unitaire",
      flex: 1.5,
      valueGetter: (params) => formatNumber(params.row.totalPriceUnitaire),
    },
    {
      field: "totalPriceTotal",
      headerName: "Prix Total",
      flex: 1.5,
      valueGetter: (params) => formatNumber(params.row.totalPriceTotal),
    },
    {
      field: "totalAvance",
      headerName: "Paiement",
      flex: 1.5,
      valueGetter: (params) => formatNumber(params.row.totalAvance),
    },
    {
      field: "totalReste",
      headerName: "Reste",
      flex: 1,
      valueGetter: (params) => formatNumber(params.row.totalReste),
    },
    {
      field: "userName",
      headerName: "Créé par",
      flex: 1.5,
    },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/commande-details/${params.row.id}`}>
              <Button variant="contained" color="primary" size="small">
                Détails
              </Button>
            </Link>
            <Link to={`/commande-payment/${params.row.id}`}>
              <Button variant="contained" color="secondary" size="small">
                Paiement
              </Button>
            </Link>
            {data?.role === "superadmin" && (
              <DeleteOutline
                className="userListDelete"
                onClick={() => handleDelete(params.row.id)}
              />
            )}
          </>
        );
      },
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <Header
        title="Commandes avec Paiements"
        subtitle="Liste des Commandes associées à des Paiements"
      />
      <FlexBetween>
        <Box>
          <Link to="/Comptabilité">
            <Button variant={"outlined"} color="secondary" sx={{ mr: 1 }}>
              Toutes les commandes
            </Button>
          </Link>
          <Link to="/Dette">
            <Button variant={"outlined"} color="secondary" sx={{ mr: 1 }}>
              Commandes en dette
            </Button>
          </Link>
          <Link to="/payment-recent">
            <Button variant={"contained"} color="secondary" sx={{ mr: 1 }}>
              Payement recent
            </Button>
          </Link>
        </Box>
        {/* <TextField
          id="search"
          label="Rechercher"
          type="text"
          value={searchQuery}
          onChange={handleSearch}
          sx={{ width: "300px", marginRight: "1rem" }}
        /> */}
      </FlexBetween>
      {loading ? (
        <Box display="flex" justifyContent="center" mt="2rem">
          <CircularProgress />
        </Box>
      ) : (
        <Box
          height="80vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <DataGrid
            rows={prestations.map((commande) => ({
              id: commande._id,
              nomClient: commande.nomClient,
              userName: commande.userName,
              status: commande.status,
              contact: commande.contact,
              commande: commande.prestations[0]?.commande || "",
              totalQty: commande.totalQty,
              totalPriceUnitaire: commande.totalPriceUnitaire,
              totalPriceTotal: commande.totalPriceTotal,
              totalAvance: commande.totalAvance,
              totalReste: commande.totalReste,
              desc: commande.prestations[0]?.desc || "",
              createdAt: commande.createdAt
                ? commande.createdAt.slice(0, 10)
                : "",
              prestations: commande.prestations,
            }))}
            columns={columns}
            pageSize={8}
            components={{ Toolbar: GridToolbar }}
          />
        </Box>
      )}
      <Popup
        title="Créer une nouvelle commande"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <FormMainCommand setOpenPopup={setOpenPopup} />
      </Popup>
    </Box>
  );
};

export default DailyCommandeWithPayments;
