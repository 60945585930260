import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { server } from "../../../src/server";
import FlexBetween from "components/FlexBetween";
import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { toast } from "react-toastify";
import { ArrowLeftSharp } from "@mui/icons-material";

const PendingProductEdit = () => {
  const { id } = useParams();
  const [productInfo, setProductInfo] = useState(null);
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const [formData, setFormData] = useState({
    name: "",
    desc: "",
    price_achat: 0,
    stock: 0,
  });

  useEffect(() => {
    const fetch = async () => {
      const response = await axios.get(
        `${server}/product/one-pendingProduct/${id}`
      );
      setProductInfo(response.data.OnePendingProducts);
      // Populate formData with initial values from products
      setFormData({
        name: response.data.OnePendingProducts.name,
        desc: response.data.OnePendingProducts.desc,
        stock: response.data.OnePendingProducts.stock,
      });
    };

    fetch();
  }, [id]);

  console.log(productInfo);

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        `${server}/product/approvePendingProduct/${id}`,
        formData,
        { withCredentials: true }
      );
      toast.success("Prestation updated successful");
      setTimeout(() => {
        window.location.replace("/achats");
      }, 1000);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  return (
    <div>
      {productInfo ? (
        <div>
          <Box m="20px">
            <FlexBetween>
              <Link to={"/achats"}>
                <Button
                  color="secondary"
                  variant="contained"
                  style={{ marginBottom: "6px" }}
                >
                  <ArrowLeftSharp />
                </Button>
              </Link>
              <Header title="Modifié un produit" />
            </FlexBetween>

            <Formik>
              {({ values, errors, touched, handleBlur, handleChange }) => (
                <form onSubmit={handleUpdate}>
                  <Box
                    display="grid"
                    marginTop="20px"
                    gap="30px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                      "& > div": {
                        gridColumn: isNonMobile ? undefined : "span 4",
                      },
                    }}
                  >
                    <TextField
                      fullWidth
                      variant="filled"
                      type="string"
                      label="Nom du produit"
                      value={formData.name}
                      onChange={(e) =>
                        setFormData({ ...formData, name: e.target.value })
                      }
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="string"
                      label="Description"
                      value={formData.desc}
                      onChange={(e) =>
                        setFormData({ ...formData, desc: e.target.value })
                      }
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="number"
                      label="Prix d'achat"
                      value={formData.price_achat}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          price_achat: e.target.value,
                        })
                      }
                      sx={{ gridColumn: "span 4" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="number"
                      label="Stock"
                      value={formData.stock}
                      onChange={(e) =>
                        setFormData({ ...formData, stock: e.target.value })
                      }
                      sx={{ gridColumn: "span 4" }}
                    />
                  </Box>
                  <Box display="flex" justifyContent="end" mt="20px">
                    <Button color="secondary" variant="contained" type="submit">
                      Modifié le produit
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </div>
      ) : (
        <div>No prestation found</div>
      )}
    </div>
  );
};

export default PendingProductEdit;
