import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  IconButton,
  Grid,
} from "@mui/material";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import Header from "../../components/Header";
import FlexBetween from "components/FlexBetween";
import axios from "axios";
import { server } from "../../../src/server";
import { useSelector } from "react-redux";
import { useGetUserQuery } from "state/api";
import { toast } from "react-toastify";

const FormAddCommand = ({ openPopup, setOpenPopup, id }) => {
  const userId = useSelector((state) => state.global.userId);
  const { data } = useGetUserQuery(userId);

  const [prestations, setPrestations] = useState([
    {
      commande: "",
      qty: 0,
      price_unitaire: 0,
      price_total: 0,
      avance: 0,
      reste: 0,
      desc: "",
      relation: "",
      personContactName: "",
      personContactPhone: "",
      assujettiTVA: false,
    },
  ]);

  // Function to handle adding a new prestation
  const handleAddPrestation = () => {
    setPrestations([
      ...prestations,
      {
        commande: "",
        qty: 0,
        price_unitaire: 0,
        price_total: 0,
        avance: 0,
        reste: 0,
        desc: "",
        relation: "",
        personContactName: "",
        personContactPhone: "",
        assujettiTVA: false,
      },
    ]);
  };

  // Function to handle removing a prestation
  const handleRemovePrestation = (index) => {
    const newPrestations = prestations.filter((_, i) => i !== index);
    setPrestations(newPrestations);
  };

  // Function to handle changes in a prestation
  const handlePrestationChange = (index, event) => {
    const { name, value, type, checked } = event.target;
    const newPrestations = [...prestations];

    if (type === "checkbox") {
      newPrestations[index][name] = checked;
    } else {
      newPrestations[index][name] = value;
    }

    // Convert numerical fields to numbers
    if (["qty", "price_unitaire", "avance"].includes(name)) {
      newPrestations[index][name] = Number(value);
    }

    // Recalculate price_total and reste
    newPrestations[index].price_total =
      Number(newPrestations[index].qty) *
      Number(newPrestations[index].price_unitaire);

    newPrestations[index].reste =
      Number(newPrestations[index].price_total) -
      Number(newPrestations[index].avance);

    setPrestations(newPrestations);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Log data being sent
      await axios.put(`${server}/commande/add-commande/${id}`, {
        prestations,
      });
      window.location.reload(true);
    } catch (error) {
      toast.error(error.response?.data?.msg || "Une erreur est survenue");
    }
  };

  return (
    <Box m="20px">
      <FlexBetween>
        <Header title="AJOUTER DES COMMANDES" />
        <Button
          color="secondary"
          variant="contained"
          onClick={() => setOpenPopup(false)}
        >
          X
        </Button>
      </FlexBetween>

      <>
        {/* Prestations List */}
        {prestations.map((prestation, index) => (
          <Box
            key={index}
            sx={{
              border: "1px solid #ccc",
              padding: "20px",
              marginBottom: "20px",
              borderRadius: "8px",
            }}
          >
            <Grid container spacing={2}>
              {/* Person Contact Details for this prestation */}
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  variant="filled"
                  type="string"
                  label="Person de contact"
                  onChange={(e) => handlePrestationChange(index, e)}
                  name="personContactName"
                  value={prestation.personContactName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  variant="filled"
                  type="string"
                  label="Téléphone du contact"
                  onChange={(e) => handlePrestationChange(index, e)}
                  name="personContactPhone"
                  value={prestation.personContactPhone}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={prestation.assujettiTVA}
                      onChange={(e) => handlePrestationChange(index, e)}
                      name="assujettiTVA"
                      color="primary"
                    />
                  }
                  label="Assujetti à la TVA (ce commande)"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="filled"
                  type="string"
                  label="La commande"
                  onChange={(e) => handlePrestationChange(index, e)}
                  name="commande"
                  value={prestation.commande}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  label="La quantité"
                  onChange={(e) => handlePrestationChange(index, e)}
                  name="qty"
                  value={prestation.qty}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  label="Prix unitaire"
                  onChange={(e) => handlePrestationChange(index, e)}
                  name="price_unitaire"
                  value={prestation.price_unitaire}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth
                  variant="filled"
                  readOnly
                  type="number"
                  label={`Prix total (PT)`}
                  value={prestation.price_total}
                  name="price_total"
                />
              </Grid>

              {/* <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  label="Avance"
                  onChange={(e) => handlePrestationChange(index, e)}
                  name="avance"
                  value={prestation.avance}
                />
              </Grid> */}
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  variant="filled"
                  readOnly
                  type="number"
                  label={`Le reste`}
                  value={prestation.reste}
                  name="reste"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  variant="filled"
                  type="string"
                  label="Observation"
                  onChange={(e) => handlePrestationChange(index, e)}
                  name="desc"
                  value={prestation.desc}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  variant="filled"
                  label="Date du rendez-vous"
                  type="date"
                  value={prestation.relation}
                  onChange={(e) => handlePrestationChange(index, e)}
                  name="relation"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} style={{ textAlign: "right" }}>
                <IconButton onClick={() => handleRemovePrestation(index)}>
                  <RemoveCircleOutline color="error" />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        ))}

        <Box style={{ textAlign: "left", marginBottom: "20px" }}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleAddPrestation}
            startIcon={<AddCircleOutline />}
          >
            Ajouter une commande
          </Button>
        </Box>

        <Box display="flex" justifyContent="end" mt="20px">
          <Button color="secondary" variant="contained" onClick={handleSubmit}>
            Ajouter les commandes
          </Button>
        </Box>
      </>
    </Box>
  );
};

export default FormAddCommand;
