// PdfCommande.js

import axios from "axios";
import { useState, useEffect } from "react";
import { Box, Button, Grid, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Header from "components/Header";
import PopupConfirm from "components/PopupConfirm";
import { Link } from "react-router-dom";
import { ArrowLeftSharp, DeleteOutline } from "@mui/icons-material";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { toast } from "react-toastify";
import FlexBetween from "components/FlexBetween";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import { server } from "../../../src/server";
import { useSelector } from "react-redux";
import { useGetUserQuery } from "state/api";
import pdfPicture from "../../assets/pdf-svgrepo-com.svg";
import Logo from "../../assets/ILLUSTRATION LOGO BISMOS .png";

const PdfCommande = () => {
  const theme = useTheme();
  const [pdfs, setPdfs] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [pdfDoc, setPdfDoc] = useState(null);
  const [pdf, setPdf] = useState(null);
  const [open, setOpen] = useState(false);
  const userId = useSelector((state) => state.global.userId);
  const { data } = useGetUserQuery(userId);

  const handleDelete = async (id) => {
    try {
      if (window.confirm("Veux-tu supprimer ce PDF ?")) {
        const response = await axios.delete(
          `${server}/generate-pdf-commande/delete-pdf-commande/${id}`
        );
        if (response.data.success) {
          window.location.reload(true);
        }
      }
    } catch (error) {
      console.error("Error deleting PDF:", error);
      toast.error("Une erreur s'est produite lors de la suppression du PDF.");
    }
  };

  useEffect(() => {
    const fetchPDFs = async () => {
      try {
        const res = await axios.get(`${server}/generate-pdf-commande/pdfs`);
        setPdfs(res.data);
      } catch (error) {
        console.error("Error fetching PDFs:", error);
      }
    };

    fetchPDFs();
  }, []);

  const handleViewPaymentDetails = async (id) => {
    try {
      const response = await axios.get(
        `${server}/generate-pdf-commande/PdfCommande/${id}`
      );
      const pdfData = response.data;

      const doc = new jsPDF();

      // Add company logo
      const imgWidth = 50;
      const imgHeight = 20;
      const imgX = 80;
      const imgY = 10;
      doc.addImage(Logo, "PNG", imgX, imgY, imgWidth, imgHeight);

      // Company Information
      doc.setFontSize(16);
      doc.text("Bismos High Tech", 14, 40);
      doc.setFontSize(12);
      doc.text("NIF: 400045190/RC:03436", 14, 46);
      doc.text("Avenue: KIGARAMA, Ngozi N0: 21", 14, 52);
      doc.text("Tel: 79 967 624 / 22303165", 14, 58);
      doc.text("www.bismos.com", 14, 64);
      doc.text("E-mail: info@bismos.com", 14, 70);

      // Add a line separator
      doc.line(14, 74, 196, 74);

      // Client Information
      doc.setFontSize(14);
      doc.text(`Facture pour ${pdfData.nomClient}`, 14, 84);
      doc.setFontSize(12);
      doc.text(`Contact: ${pdfData.ContactNumber}`, 14, 90);

      // Invoice Date and Number
      const formattedDate = new Date(pdfData.createdAt).toLocaleDateString(
        "fr-FR",
        { year: "numeric", month: "long", day: "2-digit" }
      );
      doc.text(`Date: ${formattedDate}`, 150, 84);
      doc.text(`Facture N°: ${pdfData.invoiceNumber}`, 150, 90);

      const tableColumn = [
        "Commande",
        "Description",
        "Quantité",
        "Prix unitaire",
        "Prix total",
        "Date",
      ];
      const tableRows = [];

      pdfData.items.forEach((item) => {
        const itemData = [
          item.item,
          item.description,
          item.prix,
          item.nombre_page,
          item.color,
          new Date(item.date).toLocaleDateString("fr-FR"),
        ];
        tableRows.push(itemData);
      });

      autoTable(doc, {
        head: [tableColumn],
        body: tableRows,
        startY: 100,
        theme: "grid",
        styles: { fontSize: 10 },
        headStyles: { fillColor: [41, 128, 185] },
        footStyles: { fillColor: [41, 128, 185] },
        didDrawPage: (data) => {
          const str = "Page " + doc.internal.getNumberOfPages();
          doc.setFontSize(10);
          doc.text(
            str,
            data.settings.margin.left,
            doc.internal.pageSize.height - 10
          );
        },
        margin: { top: 100 },
        pageBreak: "auto",
      });

      const finalY = doc.lastAutoTable.finalY || 100;

      doc.setFontSize(12);
      doc.text(
        `Prix Total: ${Number(
          pdfData.priceTotalPriceTotal
        ).toLocaleString()} FBU`,
        14,
        finalY + 10
      );

      doc.setFontSize(10);
      doc.text(
        "Merci pour votre confiance. Bismos Print",
        14,
        doc.internal.pageSize.getHeight() - 20
      );

      setPdfDoc(doc);
      setPdf(pdfData);
      setOpen(true);
    } catch (err) {
      console.error("Error generating PDF:", err);
      toast.error("Une erreur s'est produite lors de la génération du PDF.");
    }
  };

  const handleDownloadPDF = () => {
    if (pdfDoc) {
      pdfDoc.save(`commande_${pdf?.nomClient}_${pdf?.invoiceNumber}.pdf`);
    } else {
      console.error("PDF not yet generated");
      toast.warn("Aucun PDF disponible pour le téléchargement.");
    }
  };

  const columns = [
    { field: "invoiceNumber", headerName: "Facture N°", flex: 1 },
    { field: "nomClient", headerName: "Nom du client", flex: 2 },
    { field: "url", headerName: "Nom du facture", flex: 2 },
    { field: "qty", headerName: "Quantité", flex: 1 },
    { field: "price_unitaire", headerName: "Prix unitaire", flex: 1 },
    { field: "price_total", headerName: "Prix total", flex: 1 },
    { field: "priceTotalPriceTotal", headerName: "Total Général", flex: 1 },
    { field: "userName", headerName: "Fait Par", flex: 1 },
    { field: "createdAt", headerName: "Crée le", flex: 1.5 },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => (
        <>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => handleViewPaymentDetails(params.row.id)}
          >
            <img
              src={pdfPicture}
              alt=""
              style={{
                width: "28px",
                height: "28px",
                marginRight: "10px",
              }}
            />
          </div>
          {data?.role === "superadmin" && (
            <DeleteOutline
              className="userListDelete"
              onClick={() => handleDelete(params.row.id)}
            />
          )}
        </>
      ),
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <Header
        title="Les factures des commandes"
        subtitle="Liste des factures"
      />
      <Link to="/comptabilité">
        <Button
          color="secondary"
          variant="contained"
          style={{ marginBottom: "6px" }}
        >
          <ArrowLeftSharp /> Retour
        </Button>
      </Link>
      <Box
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": { border: "none" },
          "& .MuiDataGrid-cell": { borderBottom: "none" },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
          },
        }}
      >
        <DataGrid
          rows={pdfs.map((pdf) => ({
            id: pdf._id,
            nomClient: pdf.nomClient,
            url: pdf.url,
            qty: pdf.qty,
            price_unitaire: pdf.price_unitaire,
            price_total: pdf.price_total,
            priceTotalPriceTotal: pdf.priceTotalPriceTotal,
            userName: pdf.userName,
            invoiceNumber: pdf.invoiceNumber,
            createdAt: pdf.createdAt?.slice(0, 10),
          }))}
          columns={columns}
          pageSize={8}
          components={{ Toolbar: DataGridCustomToolbar }}
        />
      </Box>
      <PopupConfirm
        title="Voulez-vous télécharger ce fichier ?"
        open={open}
        setOpen={setOpen}
      >
        <FlexBetween>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => {
              setOpen(false);
              handleDownloadPDF();
            }}
          >
            OUI
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => setOpen(false)}
          >
            Non
          </Button>
        </FlexBetween>
      </PopupConfirm>
    </Box>
  );
};

export default PdfCommande;
