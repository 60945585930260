// CommandeDetails.js

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  useTheme,
  TextField,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Header from "components/Header";
import Popup from "components/Popup";
import { Link, useParams } from "react-router-dom";
import { DeleteOutline } from "@material-ui/icons";
import FormAddCommande from "scenes/formAddCommande";
import CommandeEdit from "scenes/Commande-Edit";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import SendIcon from "@mui/icons-material/Send";
import { ArrowLeftSharp } from "@mui/icons-material";
import axios from "axios";
import { server } from "server";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import Logo from "../../assets/ILLUSTRATION LOGO BISMOS .png";
import "./commande.css";
import { useGetUserQuery } from "state/api";

const CommandeDetails = () => {
  const theme = useTheme();
  const { id } = useParams();
  const userId = useSelector((state) => state.global.userId);
  const { data } = useGetUserQuery(userId);
  const [prestations, setPrestations] = useState([]);
  const [nomClient, setNomClient] = useState("");
  const [contact, setContact] = useState("");
  const [selectedCommandeId, setSelectedCommandeId] = useState(null);
  const [selectedPrestations, setSelectedPrestations] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopup1, setOpenPopup1] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" });
  const [searchQuery, setSearchQuery] = useState("");
  const [pdfPreviewUrl, setPdfPreviewUrl] = useState(null);
  const [openPdfPreview, setOpenPdfPreview] = useState(false);
  const [docInstance, setDocInstance] = useState(null);
  const [invoiceNumber, setInvoiceNumber] = useState(null);

  const formatNumber = (number) => {
    return Number(number || 0).toLocaleString();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${server}/commande/commande/${id}`);
        const commandeData = response.data;
        let prestationsArray = commandeData.prestations || [];
        prestationsArray = prestationsArray.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setNomClient(commandeData.nomClient);
        setContact(commandeData.contact);
        setPrestations(prestationsArray);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);

  const handleDelete = async (selectedCommandeId) => {
    try {
      if (window.confirm("Veux-tu supprimer cette prestation?")) {
        const response = await axios.delete(
          `${server}/commande/delete-prestation/${id}/${selectedCommandeId}`
        );
        if (response.data.success) {
          setPrestations((prevPrestations) =>
            prevPrestations.filter((p) => p._id !== selectedCommandeId)
          );
          toast.success("Prestation supprimée avec succès");
        } else {
          toast.error("Erreur lors de la suppression de la prestation");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateStatus = async (prestationId, status) => {
    try {
      const response = await axios.put(
        `${server}/commande/update-prestation-status/${id}/${prestationId}`,
        { status }
      );

      if (response.data.success) {
        setPrestations((prevPrestations) =>
          prevPrestations.map((prestation) =>
            prestation._id === prestationId
              ? { ...prestation, status }
              : prestation
          )
        );
        toast.success("Statut de la prestation mis à jour");
      } else {
        toast.error("Échec de la mise à jour du statut");
      }
    } catch (error) {
      toast.error("Une erreur s'est produite");
    }
  };

  const handleClick = (event, rowId) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowId(rowId);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRowId(null);
  };

  const statusColors = {
    delivered: theme.palette.success.main,
    not_delivered: theme.palette.error.main,
    half_delivered: theme.palette.warning.main,
    not_paid_delivered: theme.palette.grey[500],
    production: theme.palette.info.main,
    pending: theme.palette.secondary.main,
    not_production: theme.palette.error.main,
  };

  const statusLabels = {
    delivered: "Livré",
    not_delivered: "Non livré",
    half_delivered: "Livré avec dette",
    not_paid_delivered: "Livré sans paiement",
    production: "En production",
    pending: "En attente",
    not_production: "Pas en production",
  };

  const handleSelectionChange = (prestationId) => {
    setSelectedPrestations((prevSelected) => {
      if (prevSelected.includes(prestationId)) {
        return prevSelected.filter((id) => id !== prestationId);
      } else {
        return [...prevSelected, prestationId];
      }
    });
  };

  const handleSelectAll = () => {
    const allIds = filteredPrestations.map((prestation) => prestation._id);
    setSelectedPrestations(allIds);
  };

  const handleDeselectAll = () => {
    setSelectedPrestations([]);
  };

  const handleDownloadPDF = async () => {
    if (selectedPrestations.length === 0) {
      toast.error("Veuillez sélectionner au moins une prestation");
      return;
    }

    const selectedData = prestations.filter((prestation) =>
      selectedPrestations.includes(prestation._id)
    );

    if (selectedData.length === 0) {
      toast.error("Aucune prestation correspondante trouvée");
      return;
    }

    const totalPriceTotal = selectedData.reduce(
      (sum, item) => sum + (parseFloat(item.price_total) || 0),
      0
    );

    try {
      const response = await axios.post(`${server}/generate-pdf-commande`, {
        nomClient,
        contact,
        prestations: selectedData,
        userName: data?.name,
        totalPriceTotal,
      });

      if (response.data.success) {
        const { invoiceNumber, formattedDate } = response.data;
        setInvoiceNumber(invoiceNumber);

        const doc = new jsPDF();

        const imgWidth = 50;
        const imgHeight = 20;
        const imgX = 80;
        const imgY = 10;
        doc.addImage(Logo, "PNG", imgX, imgY, imgWidth, imgHeight);

        doc.setFontSize(16);
        doc.text("Bismos High Tech", 14, 40);
        doc.setFontSize(12);
        doc.text("NIF: 400045190/RC:03436", 14, 46);
        doc.text("Avenue: KIGARAMA, Ngozi N0: 21", 14, 52);
        doc.text("Tel: 79 967 624 / 22303165", 14, 58);
        doc.text("www.bismos.com", 14, 64);
        doc.text("E-mail: info@bismos.com", 14, 70);

        doc.line(14, 74, 196, 74);

        doc.setFontSize(14);
        doc.text(`Facture pour ${nomClient}`, 14, 84);
        doc.setFontSize(12);
        doc.text(`Contact: ${contact}`, 14, 90);

        doc.text(`Date: ${formattedDate}`, 150, 84);
        doc.text(`Facture N°: ${invoiceNumber}`, 150, 90);

        const tableColumn = [
          "Commande",
          "Quantité",
          "Prix unitaire",
          "Prix total",
          "Paiement",
          "Reste",
          "Date",
        ];
        const tableRows = [];

        selectedData.forEach((prestation) => {
          const prestationData = [
            prestation.commande,
            prestation.qty,
            formatNumber(prestation.price_unitaire),
            formatNumber(prestation.price_total),
            formatNumber(prestation.avance),
            formatNumber(prestation.reste),
            prestation.createdAt.slice(0, 10),
          ];
          tableRows.push(prestationData);
        });

        autoTable(doc, {
          head: [tableColumn],
          body: tableRows,
          startY: 100,
          theme: "grid",
          styles: { fontSize: 10 },
          headStyles: { fillColor: [41, 128, 185] },
          footStyles: { fillColor: [41, 128, 185] },
          didDrawPage: (data) => {
            const str = "Page " + doc.internal.getNumberOfPages();
            doc.setFontSize(10);
            doc.text(
              str,
              data.settings.margin.left,
              doc.internal.pageSize.height - 10
            );
          },
          margin: { top: 100 },
          pageBreak: "auto",
        });

        const totalPriceTotal = selectedData.reduce(
          (sum, item) => sum + (parseFloat(item.price_total) || 0),
          0
        );
        const totalAvance = selectedData.reduce(
          (sum, item) => sum + (parseFloat(item.avance) || 0),
          0
        );
        const totalReste = selectedData.reduce(
          (sum, item) => sum + (parseFloat(item.reste) || 0),
          0
        );

        const finalY = doc.lastAutoTable.finalY || 100;

        doc.setFontSize(12);
        doc.text(
          `Prix Total: ${formatNumber(totalPriceTotal)} FBU`,
          14,
          finalY + 10
        );
        doc.text(
          `Total Paiement: ${formatNumber(totalAvance)} FBU`,
          14,
          finalY + 16
        );
        doc.text(
          `Total Reste: ${formatNumber(totalReste)} FBU`,
          14,
          finalY + 22
        );

        doc.setFontSize(10);
        doc.text(
          "Merci pour votre confiance. Bismos Print",
          14,
          doc.internal.pageSize.getHeight() - 20
        );

        const pdfBlob = doc.output("blob");
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setPdfPreviewUrl(pdfUrl);
        setOpenPdfPreview(true);
        setDocInstance(doc);
      } else {
        toast.error("Erreur lors de la génération de la facture");
      }
    } catch (error) {
      console.error("Error generating invoice:", error);
      toast.error(
        "Une erreur s'est produite lors de la génération de la facture"
      );
    }
  };

  const handleDateRangeChange = (e) => {
    const { name, value } = e.target;
    setDateRange((prev) => ({ ...prev, [name]: value }));
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredPrestations = prestations
    .filter((prestation) => {
      const prestationDate = prestation.createdAt.slice(0, 10);
      const { startDate, endDate } = dateRange;
      if (startDate && prestationDate < startDate) {
        return false;
      }
      if (endDate && prestationDate > endDate) {
        return false;
      }
      return true;
    })
    .filter((prestation) => {
      const query = searchQuery.toLowerCase();
      return (
        prestation.commande.toLowerCase().includes(query) ||
        prestation.desc.toLowerCase().includes(query)
      );
    });

  const handleClosePdfPreview = () => {
    setOpenPdfPreview(false);
    if (pdfPreviewUrl) {
      URL.revokeObjectURL(pdfPreviewUrl);
      setPdfPreviewUrl(null);
    }
    setDocInstance(null);
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header
        title={`Détails des commandes de: ${nomClient}`}
        subtitle={`Contact: ${contact}`}
      />
      <Link to="/comptabilité">
        <Button
          color="secondary"
          variant="contained"
          style={{ marginBottom: "6px" }}
        >
          <ArrowLeftSharp /> Retour
        </Button>
      </Link>
      <Grid container spacing={2} style={{ marginTop: "10px" }}>
        <Grid item>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleDownloadPDF}
            endIcon={<SendIcon />}
          >
            Générer le PDF
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleSelectAll}
          >
            Tout sélectionner
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleDeselectAll}
          >
            Tout désélectionner
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => setOpenPopup(true)}
          >
            Ajouter une prestation
          </Button>
        </Grid>
        <Grid item>
          <Button color="secondary" variant="contained" endIcon={<SendIcon />}>
            <Link
              to="/Facturation"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              Voir les factures
            </Link>
          </Button>
        </Grid>
      </Grid>
      <Box sx={{ marginTop: 2 }}>
        <TextField
          label="Date de début"
          type="date"
          name="startDate"
          value={dateRange.startDate}
          onChange={handleDateRangeChange}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ marginRight: 2 }}
        />
        <TextField
          label="Date de fin"
          type="date"
          name="endDate"
          value={dateRange.endDate}
          onChange={handleDateRangeChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Box>
      <Box sx={{ marginTop: 2 }}>
        <TextField
          label="Rechercher"
          variant="outlined"
          fullWidth
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </Box>
      <Box sx={{ marginTop: 2 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={
                      selectedPrestations.length > 0 &&
                      selectedPrestations.length < filteredPrestations.length
                    }
                    checked={
                      filteredPrestations.length > 0 &&
                      selectedPrestations.length === filteredPrestations.length
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        handleSelectAll();
                      } else {
                        handleDeselectAll();
                      }
                    }}
                  />
                </TableCell>
                <TableCell>Commande</TableCell>
                <TableCell>Quantité</TableCell>
                <TableCell>Prix unitaire</TableCell>
                <TableCell>Prix total</TableCell>
                <TableCell>Paiement</TableCell>
                <TableCell>Reste</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Statut</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredPrestations.map((prestation) => (
                <TableRow key={prestation._id}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedPrestations.includes(prestation._id)}
                      onChange={() => handleSelectionChange(prestation._id)}
                    />
                  </TableCell>
                  <TableCell>{prestation.commande}</TableCell>
                  <TableCell>{prestation.qty}</TableCell>
                  <TableCell>
                    {formatNumber(prestation.price_unitaire)}
                  </TableCell>
                  <TableCell>{formatNumber(prestation.price_total)}</TableCell>
                  <TableCell>{formatNumber(prestation.avance)}</TableCell>
                  <TableCell>{formatNumber(prestation.reste)}</TableCell>
                  <TableCell>{prestation.createdAt.slice(0, 10)}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: statusColors[prestation.status],
                      }}
                      onClick={(event) => handleClick(event, prestation._id)}
                      sx={{ width: "150px" }}
                    >
                      {statusLabels[prestation.status]}
                    </Button>
                    <Menu
                      anchorEl={anchorEl}
                      open={
                        Boolean(anchorEl) && selectedRowId === prestation._id
                      }
                      onClose={handleClose}
                    >
                      {Object.keys(statusLabels).map((status) => (
                        <MenuItem
                          key={status}
                          onClick={() => {
                            handleUpdateStatus(prestation._id, status);
                            handleClose();
                          }}
                        >
                          {statusLabels[status]}
                        </MenuItem>
                      ))}
                    </Menu>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => {
                        setSelectedCommandeId(prestation._id);
                        setOpenPopup1(true);
                      }}
                    >
                      Éditer
                    </Button>
                    <DeleteOutline
                      className="userListDelete"
                      onClick={() => {
                        setSelectedCommandeId(prestation._id);
                        handleDelete(prestation._id);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Popup
        title="Ajouter une prestation"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <FormAddCommande setOpenPopup={setOpenPopup} id={id} />
      </Popup>
      <Popup
        title="Modifier la prestation"
        openPopup={openPopup1}
        setOpenPopup={setOpenPopup1}
      >
        <CommandeEdit
          setOpenPopup1={setOpenPopup1}
          id={id}
          commandeId={selectedCommandeId}
        />
      </Popup>

      <Dialog
        open={openPdfPreview}
        onClose={handleClosePdfPreview}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Prévisualisation du PDF</DialogTitle>
        <DialogContent>
          {pdfPreviewUrl && (
            <iframe
              src={pdfPreviewUrl}
              width="100%"
              height="600px"
              title="Prévisualisation du PDF"
            ></iframe>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (docInstance) {
                docInstance.save(`Facture_${nomClient}_${invoiceNumber}.pdf`);
              }
              handleClosePdfPreview();
            }}
            color="primary"
            variant="contained"
          >
            Télécharger le PDF
          </Button>
          <Button onClick={handleClosePdfPreview} color="secondary">
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CommandeDetails;
