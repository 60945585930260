import React, { useEffect, useState } from "react";
import { Typography, Box, Grid, Divider, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios"; // Adjust the path as necessary
import PhoneIcon from "@mui/icons-material/Phone";
import PersonIcon from "@mui/icons-material/Person";
import EventIcon from "@mui/icons-material/Event";
import { useSelector } from "react-redux";
import { useGetUserQuery } from "state/api";
import { server } from "server";

const PopupComptabiliteCommande = ({ setOpenPopup, commandeId }) => {
  const [prestation, setPrestation] = useState(null);
  const userId = useSelector((state) => state.global.userId);
  const { data } = useGetUserQuery(userId);

  useEffect(() => {
    const fetchPrestationDetails = async () => {
      try {
        const response = await axios.get(
          `${server}/commande/commande/${commandeId}`
        );
        setPrestation(response.data);
      } catch (error) {
        console.error("Error fetching prestation details:", error);
      }
    };

    fetchPrestationDetails();
  }, [commandeId]);

  return (
    <Box p={2} position="relative">
      {/* Close Button */}
      <IconButton
        aria-label="close"
        onClick={() => setOpenPopup(false)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>

      {prestation ? (
        <Box mt={4}>
          <Typography variant="h5" gutterBottom>
            Détails sur ce client
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography>
                <strong>Nom du Client:</strong> {prestation.nomClient}
              </Typography>
              <Typography>
                <strong>Contact:</strong> {prestation.contact}
              </Typography>
              <Typography>
                <strong>Crée par :</strong> {prestation.userName}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                <strong>NIF:</strong> {prestation.nif}
              </Typography>
              <Typography>
                <strong>Addresse:</strong> {prestation.adresse}
              </Typography>
            </Grid>
          </Grid>

          <Divider sx={{ my: 2 }} />

          {/* Loop through prestations */}
          <Typography variant="h6">Prestations</Typography>
          {prestation.prestations.map((item, index) => (
            <Box key={item._id} mt={2}>
              <Typography>
                <strong>Commande:</strong> {item.commande}
              </Typography>
              <Typography>
                <strong>Quantity:</strong> {item.qty}
              </Typography>
              <Typography>
                <strong>Prix Unitaire:</strong> {item.price_unitaire}
              </Typography>
              <Typography>
                <strong>Prix Total:</strong> {item.price_total}
              </Typography>
              <Typography>
                <strong>Avance:</strong> {item.avance}
              </Typography>
              <Typography>
                <strong>Reste:</strong> {item.reste}
              </Typography>
              <Typography>
                <strong>Description:</strong> {item.desc}
              </Typography>
              <Typography>
                <strong>Statut:</strong> {item.status}
              </Typography>
              <Typography>
                <strong>Relation:</strong> {item.relation}
              </Typography>
              <Typography>
                <strong>Contact Person:</strong> {item.personContactName}{" "}
                <PhoneIcon fontSize="small" /> {item.personContactPhone}
              </Typography>
              <Divider sx={{ my: 2 }} />
            </Box>
          ))}

          <Divider sx={{ my: 2 }} />

          <Typography>
            <strong>Date de création:</strong> {prestation.date.slice(0, 10)}
          </Typography>
        </Box>
      ) : (
        <Typography>Chargement des détails...</Typography>
      )}
    </Box>
  );
};

export default PopupComptabiliteCommande;
