import React, { useEffect, useState } from "react";
import {
  LightModeOutlined,
  DarkModeOutlined,
  Menu as MenuIcon,
  SettingsOutlined,
  ArrowDropDownOutlined,
  Close as CloseIcon,
} from "@mui/icons-material";
import Alert from "@mui/material/Alert";
import FlexBetween from "./FlexBetween";
import { useDispatch, useSelector } from "react-redux";
import { setMode } from "state";
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useTheme,
  useMediaQuery,
  Drawer,
  List,
} from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useGetUserQuery } from "state/api";

const Navbar = ({ user, isSidebarOpen, setIsSidebarOpen }) => {
  const userId = useSelector((state) => state.global.userId);
  const { data } = useGetUserQuery(userId);
  const [products, setProducts] = useState([]);
  const [hasLowStock, setHasLowStock] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isOpen = Boolean(anchorEl);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const dispatch = useDispatch();
  const theme = useTheme();

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const toggleDrawer = () => setDrawerOpen(!drawerOpen);

  const clearAllTokens = async () => {
    try {
      localStorage.removeItem("secretKeyesforzado7");
      toast.success("Logged out successfully");
      window.location.reload(true);
    } catch (error) {
      console.error("Error clearing all tokens:", error);
      toast.error("An error occurred");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/product/get-all-products`);
        const allProducts = response.data.allproducts;
        setProducts(allProducts);
        setHasLowStock(allProducts.some((product) => product.stock < 10));
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchData();
  }, []);

  const renderMenuItems = () => (
    <>
      {data?.role === "superadmin" && hasLowStock && (
        <MenuItem onClick={handleClose}>
          <Link to="/avertissement">
            <Alert severity="warning" />
          </Link>
        </MenuItem>
      )}
      {data?.role === "gestionnaire" && hasLowStock && (
        <MenuItem onClick={handleClose}>
          <Link to="/avertissement">
            <Alert severity="warning" />
          </Link>
        </MenuItem>
      )}
      <MenuItem onClick={handleClose}>
        <IconButton onClick={() => dispatch(setMode())} size="large">
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlined fontSize="large" />
          ) : (
            <LightModeOutlined fontSize="large" />
          )}
        </IconButton>
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <Link to="/settings">
          <IconButton size="large">
            <SettingsOutlined fontSize="large" />
          </IconButton>
        </Link>
      </MenuItem>
    </>
  );

  return (
    <AppBar sx={{ position: "static", background: "none", boxShadow: "none" }}>
      <Toolbar sx={{ justifyContent: "space-between", padding: "0 1rem" }}>
        {/* LEFT SIDE */}
        <FlexBetween>
          <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
            <MenuIcon fontSize="large" />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: theme.palette.background.alt,
              borderRadius: "9px",
              padding: "0.2rem 1.75rem",
              gap: "1rem",
            }}
          >
            <Typography
              variant="h5"
              fontWeight="bold"
              color={theme.palette.secondary.main}
            >
              BISMOS PRINT: IMPREMERIE
            </Typography>
          </Box>
        </FlexBetween>

        {/* RIGHT SIDE */}
        {!isMobile ? (
          <FlexBetween gap="2rem">
            {renderMenuItems()}
            <FlexBetween>
              <Button
                onClick={handleClick}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  textTransform: "none",
                  gap: "1.5rem",
                }}
              >
                <Box
                  component="img"
                  alt="profile"
                  src={`${user?.profilepic?.url}`}
                  height="40px"
                  width="40px"
                  borderRadius="50%"
                  sx={{ objectFit: "cover" }}
                />
                <Box textAlign="left">
                  <Typography
                    fontWeight="bold"
                    fontSize="0.9rem"
                    sx={{ color: theme.palette.secondary[100] }}
                  >
                    {user?.name}
                  </Typography>
                  <Typography
                    fontSize="0.8rem"
                    sx={{ color: theme.palette.secondary[200] }}
                  >
                    {user?.occupation}
                  </Typography>
                </Box>
                <ArrowDropDownOutlined
                  sx={{ color: theme.palette.secondary[300], fontSize: "25px" }}
                />
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={isOpen}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              >
                <MenuItem onClick={clearAllTokens}>Log Out</MenuItem>
              </Menu>
            </FlexBetween>
          </FlexBetween>
        ) : (
          <IconButton onClick={toggleDrawer}>
            <Box
              component="img"
              alt="profile"
              src={`${user?.profilepic?.url}`}
              height="32px"
              width="32px"
              borderRadius="50%"
              sx={{ objectFit: "cover" }}
            />
          </IconButton>
        )}
      </Toolbar>
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer}>
        <Box sx={{ width: 250, padding: "1rem" }}>
          <IconButton onClick={toggleDrawer}>
            <CloseIcon fontSize="large" />
          </IconButton>
          <List>{renderMenuItems()}</List>
        </Box>
      </Drawer>
    </AppBar>
  );
};

export default Navbar;
