// Commande.js

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  TextField,
  useTheme,
  IconButton,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Header from "components/Header";
import Popup from "components/Popup";
import { Link } from "react-router-dom";
import { DeleteOutline } from "@material-ui/icons";
import { useGetUserQuery } from "state/api";
import axios from "axios";
import { server } from "server";
import "./commande.css";
import DataGridCustomToolbarCommande from "components/DataGridCustomToolBarCommande";
import FormMainCommand from "scenes/formMainCommand";
import { toast } from "react-toastify";
import FlexBetween from "components/FlexBetween";
import { useSelector } from "react-redux";
import PopupComptabiliteCommande from "components/PopupComptabiliteCommande";
import VisibilityIcon from "@mui/icons-material/Visibility";

const Commande = () => {
  const theme = useTheme();
  const [selectedDate, setSelectedDate] = useState("");
  const [loading, setLoading] = useState(false);
  const userId = useSelector((state) => state.global.userId);
  const { data } = useGetUserQuery(userId);
  const [id, setId] = useState(null);
  const [prestations, setPrestations] = useState([]);
  const [openPopup2, setOpenPopup2] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${server}/commande/all-commandes-with-totals`
      );
      const prestationsArray = response.data.commandes || [];
      setPrestations(prestationsArray);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const fetchCommandesByDate = async (date) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${server}/commande/commandes-by-date-with-totals/${date}`
      );
      setPrestations(response.data.commandes);
      setLoading(false);
    } catch (error) {
      toast.error("Ces données ne sont pas disponibles");
      setLoading(false);
    }
  };

  const formatNumber = (number) => {
    return Number(number || 0).toLocaleString();
  };

  const handleDateChange = (event) => {
    const date = event.target.value;
    setSelectedDate(date);
    if (date) {
      fetchCommandesByDate(date);
    } else {
      fetchData();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [openPopup, setOpenPopup] = useState(false);

  const handleUpdateStatus = async (id, status) => {
    try {
      const response = await axios.put(
        `${server}/commande/update-commande-status/${id}`,
        { status }
      );

      if (response.data.success) {
        toast.success("Statut du produit a été mis à jour");
        setTimeout(() => {
          window.location.reload(true);
        }, 1000);
      } else {
      }
    } catch (error) {
      toast.error("Une erreur s'est produite");
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState(null);

  const handleClick = (event, rowId) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowId(rowId);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRowId(null);
  };

  const statusColors = {
    delivered: theme.palette.success.main,
    not_delivered: theme.palette.error.main,
    half_delivered: theme.palette.warning.main,
    not_paid_delivered: theme.palette.grey[500],
    production: theme.palette.info.main,
    pending: theme.palette.secondary.main,
    not_production: theme.palette.error.main,
  };

  const statusLabels = {
    delivered: "Livré",
    not_delivered: "Non livré",
    half_delivered: "Livré avec dette",
    not_paid_delivered: "Livré sans paiement",
    production: "En production",
    pending: "En attente de récupération",
    not_production: "Pas encore en production",
  };

  const handleDelete = async (id) => {
    try {
      if (window.confirm("Veux-tu supprimer cette commande?")) {
        const response = await axios.delete(
          `${server}/commande/delete-commande/${id}`
        );
        if (response) {
          toast.success("Commande supprimée avec succès");
          fetchData();
        } else {
        }
      }
    } catch (error) {
      console.log(error);
      toast.error("Utilisateur introuvable");
    }
  };

  const columns = [
    {
      field: "createdAt",
      headerName: "Fait le",
      flex: 2,
    },
    {
      field: "nomClient",
      headerName: "Nom Client",
      flex: 2,
    },
    {
      field: "contact",
      headerName: "Contact",
      flex: 1,
    },
    {
      field: "totalQty",
      headerName: "Quantité",
      flex: 1,
      valueGetter: (params) => formatNumber(params.row.totalQty),
    },
    {
      field: "action",
      headerName: "Action",
      width: 190,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              onClick={() => {
                setOpenPopup2(true);
                setId(params.row.id);
              }}
            >
              <VisibilityIcon />
            </IconButton>
            <Link to={"/commande-details-all/" + params.row.id}>
              <button className="userListEdit">Détails</button>
            </Link>
            {data?.role === "superadmin" && (
              <DeleteOutline
                className="userListDelete"
                onClick={() => handleDelete(params.row.id)}
              />
            )}
          </>
        );
      },
    },
  ];

  const filteredPrestations = prestations.filter((prestation) =>
    prestation.nomClient.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="COMMANDES" subtitle="Liste des Commandes" />
      <FlexBetween>
        <Link
          to="/prestations/not-production"
          style={{ textDecoration: "none" }}
        >
          <Button variant="contained" color="primary">
            Commandes Pas en Production
          </Button>
        </Link>
        <Link to="/prestations/production">
          <Button variant="contained" color="primary">
            Commandes en Production
          </Button>
        </Link>
        <Link to="/prestations/pending">
          <Button variant="contained" color="primary">
            Commandes en Attente
          </Button>
        </Link>
        <Link to="/prestations/delivered">
          <Button variant="contained" color="primary">
            Commandes Livrées
          </Button>
        </Link>
        <TextField
          variant="outlined"
          placeholder="Rechercher par Client"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ marginLeft: 2 }}
        />
      </FlexBetween>
      {loading ? (
        <Box display="flex" justifyContent="center" mt="2rem">
          <CircularProgress />
        </Box>
      ) : (
        <Box
          height="80vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <DataGrid
            rows={filteredPrestations.map((prestation) => ({
              id: prestation._id,
              nomClient: prestation.nomClient,
              userName: prestation.userName,
              status: prestation.status,
              contact: prestation.contact,
              commande: prestation.prestations[0]?.commande || "",
              totalQty: prestation.totalQty,
              totalPriceUnitaire: prestation.totalPriceUnitaire,
              totalPriceTotal: prestation.totalPriceTotal,
              totalAvance: prestation.totalAvance,
              totalReste: prestation.totalReste,
              desc: prestation.prestations[0]?.desc || "",
              createdAt: prestation.createdAt
                ? prestation.createdAt.slice(0, 10)
                : "",
            }))}
            columns={columns}
            pageSize={8}
            components={{ Toolbar: DataGridCustomToolbarCommande }}
            componentsProps={{
              toolbar: { openPopup, setOpenPopup },
            }}
          />
        </Box>
      )}
      <Popup
        title="Ajouter un nouveau client"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <FormMainCommand setOpenPopup={setOpenPopup} />
      </Popup>
      <Popup
        title="Détails de la Commande"
        openPopup={openPopup2}
        setOpenPopup={setOpenPopup2}
      >
        <PopupComptabiliteCommande
          setOpenPopup={setOpenPopup2}
          commandeId={id}
        />
      </Popup>
    </Box>
  );
};

export default Commande;
